/**
 * How often data in components should be re-fetched.
 */
export const FLUGSICHERUNG_RELOAD_DATA_INTERVAL = 10_000 // ms

/**
 * The CTF's name.
 */
export const CTF_TITLE = 'NetSec CTF'
export const CTF_TITLE_DATIV = CTF_TITLE

/**
 * The CTF's start date.
 * Used for the landing page.
 */
export const CTF_START = new Date(Date.UTC(2024, 3, 9, 11, 0, 0, 0))

/**
 * The CTF's end date.
 * Used for the landing page.
 */
export const CTF_END = new Date(Date.UTC(2024, 4, 8, 21, 59, 59, 0))

/**
 * The end for post play time.
 * Used for the landing page.
 */
export const CTF_POST_PLAY_TIME_END = new Date(CTF_END.getTime() + 14 * 24 * 60 * 60 * 1000)

/**
 * Name and extension that are used for the VPN config file when downloaded via the UI.
 */
export const VPN_CONFIG_FILE_NAME = 'netsec-vpn'
export const VPN_CONFIG_FILE_EXTENSION = 'conf'

/**
 * Registration link.
 */
export const REGISTER_LINK = 'https://ctf.network-security.net/'

/**
 * The number of challenges that are available in the CTF (total).
 */
export const CTF_NUMBER_CHALLENGES = 21

/**
 * The number of challenges that are available at the start (without any flags).
 */
export const CTF_NUMBER_CHALLENGES_START = 'einige'

/**
 * General contact E-Mail address.
 */
export const CTF_CONTACT_EMAIL = 'tobias.kantusch@student.hpi.de'
export const CTF_CONTACT_EMAIL_LINK = `mailto:${CTF_CONTACT_EMAIL}`

/**
 * Discord
 */
export const CTF_DISCORD_SERVER_ADDRESS = null

/**
 * Advertisement Videos.
 */
export const CTF_YOUTUBE_VIDEO = 'https://www.youtube-nocookie.com/embed/x3YQYSfEYyw?rel=0'

/**
 * The duration of the fireworks animation after competition start.
 * Given in milliseconds.
 */
export const FIREWORKS_ANIMATION_DURATION = 24 * 60 * 60 * 1000
