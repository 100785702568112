/* tslint:disable */
/* eslint-disable */
/**
 * Flugsicherung API
 * This API is used for CTF hosting.
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * The user's score.
 * 
 * This is `null`, when there is no score available.
 * @export
 * @interface HandlerUserResponseScore
 */
export interface HandlerUserResponseScore {
    /**
     * The number of solved flags.
     * @type {number}
     * @memberof HandlerUserResponseScore
     */
    numSolvedFlags: number;
    /**
     * The user's points.
     * @type {number}
     * @memberof HandlerUserResponseScore
     */
    points: number;
    /**
     * The user's rank.
     * @type {number}
     * @memberof HandlerUserResponseScore
     */
    rank: number;
}

/**
 * Check if a given object implements the HandlerUserResponseScore interface.
 */
export function instanceOfHandlerUserResponseScore(value: object): value is HandlerUserResponseScore {
    if (!('numSolvedFlags' in value) || value['numSolvedFlags'] === undefined) return false;
    if (!('points' in value) || value['points'] === undefined) return false;
    if (!('rank' in value) || value['rank'] === undefined) return false;
    return true;
}

export function HandlerUserResponseScoreFromJSON(json: any): HandlerUserResponseScore {
    return HandlerUserResponseScoreFromJSONTyped(json, false);
}

export function HandlerUserResponseScoreFromJSONTyped(json: any, ignoreDiscriminator: boolean): HandlerUserResponseScore {
    if (json == null) {
        return json;
    }
    return {
        
        'numSolvedFlags': json['numSolvedFlags'],
        'points': json['points'],
        'rank': json['rank'],
    };
}

export function HandlerUserResponseScoreToJSON(value?: HandlerUserResponseScore | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'numSolvedFlags': value['numSolvedFlags'],
        'points': value['points'],
        'rank': value['rank'],
    };
}

