/* tslint:disable */
/* eslint-disable */
/**
 * Flugsicherung API
 * This API is used for CTF hosting.
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { HandlerUserRunningChallenges } from './HandlerUserRunningChallenges';
import {
    HandlerUserRunningChallengesFromJSON,
    HandlerUserRunningChallengesFromJSONTyped,
    HandlerUserRunningChallengesToJSON,
} from './HandlerUserRunningChallenges';
import type { HandlerUserResponseScore } from './HandlerUserResponseScore';
import {
    HandlerUserResponseScoreFromJSON,
    HandlerUserResponseScoreFromJSONTyped,
    HandlerUserResponseScoreToJSON,
} from './HandlerUserResponseScore';
import type { HandlerChallengeIdentifier } from './HandlerChallengeIdentifier';
import {
    HandlerChallengeIdentifierFromJSON,
    HandlerChallengeIdentifierFromJSONTyped,
    HandlerChallengeIdentifierToJSON,
} from './HandlerChallengeIdentifier';
import type { HandlerUserVPN } from './HandlerUserVPN';
import {
    HandlerUserVPNFromJSON,
    HandlerUserVPNFromJSONTyped,
    HandlerUserVPNToJSON,
} from './HandlerUserVPN';

/**
 * The user response.
 * @export
 * @interface HandlerUserResponse
 */
export interface HandlerUserResponse {
    /**
     * The list of completed challenges for this participant.
     * @type {Array<HandlerChallengeIdentifier>}
     * @memberof HandlerUserResponse
     */
    completedChallenges: Array<HandlerChallengeIdentifier>;
    /**
     * The code used for identifying users in third-party systems.
     * @type {string}
     * @memberof HandlerUserResponse
     */
    externalId: string;
    /**
     * Whether the user selected a score group.
     * @type {boolean}
     * @memberof HandlerUserResponse
     */
    hasSelectedScoreGroup: boolean;
    /**
     * The ID of the user.
     * @type {string}
     * @memberof HandlerUserResponse
     */
    id: string;
    /**
     * Information about running challenge instances.
     * @type {HandlerUserRunningChallenges}
     * @memberof HandlerUserResponse
     */
    runningChallenges: HandlerUserRunningChallenges;
    /**
     * 
     * @type {HandlerUserResponseScore}
     * @memberof HandlerUserResponse
     */
    score: HandlerUserResponseScore | null;
    /**
     * The chosen scoring group of the user.´
     * @type {string}
     * @memberof HandlerUserResponse
     */
    scoreGroup: string;
    /**
     * The name of the user.
     * @type {string}
     * @memberof HandlerUserResponse
     */
    username: string;
    /**
     * The VPN configuration of the user.
     * @type {HandlerUserVPN}
     * @memberof HandlerUserResponse
     */
    vpn: HandlerUserVPN;
}

/**
 * Check if a given object implements the HandlerUserResponse interface.
 */
export function instanceOfHandlerUserResponse(value: object): value is HandlerUserResponse {
    if (!('completedChallenges' in value) || value['completedChallenges'] === undefined) return false;
    if (!('externalId' in value) || value['externalId'] === undefined) return false;
    if (!('hasSelectedScoreGroup' in value) || value['hasSelectedScoreGroup'] === undefined) return false;
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('runningChallenges' in value) || value['runningChallenges'] === undefined) return false;
    if (!('score' in value) || value['score'] === undefined) return false;
    if (!('scoreGroup' in value) || value['scoreGroup'] === undefined) return false;
    if (!('username' in value) || value['username'] === undefined) return false;
    if (!('vpn' in value) || value['vpn'] === undefined) return false;
    return true;
}

export function HandlerUserResponseFromJSON(json: any): HandlerUserResponse {
    return HandlerUserResponseFromJSONTyped(json, false);
}

export function HandlerUserResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): HandlerUserResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'completedChallenges': ((json['completedChallenges'] as Array<any>).map(HandlerChallengeIdentifierFromJSON)),
        'externalId': json['externalId'],
        'hasSelectedScoreGroup': json['hasSelectedScoreGroup'],
        'id': json['id'],
        'runningChallenges': HandlerUserRunningChallengesFromJSON(json['runningChallenges']),
        'score': HandlerUserResponseScoreFromJSON(json['score']),
        'scoreGroup': json['scoreGroup'],
        'username': json['username'],
        'vpn': HandlerUserVPNFromJSON(json['vpn']),
    };
}

export function HandlerUserResponseToJSON(value?: HandlerUserResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'completedChallenges': ((value['completedChallenges'] as Array<any>).map(HandlerChallengeIdentifierToJSON)),
        'externalId': value['externalId'],
        'hasSelectedScoreGroup': value['hasSelectedScoreGroup'],
        'id': value['id'],
        'runningChallenges': HandlerUserRunningChallengesToJSON(value['runningChallenges']),
        'score': HandlerUserResponseScoreToJSON(value['score']),
        'scoreGroup': value['scoreGroup'],
        'username': value['username'],
        'vpn': HandlerUserVPNToJSON(value['vpn']),
    };
}

