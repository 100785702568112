<template>
  <Modal
    :modelValue="props.modelValue"
    :title="$t('discord.title')"
    @update:model-value="emits('update:modelValue', $event)"
  >
    <div class="join-step">
      <div class="step-icon">
        <UserPlusIcon />
      </div>
      <div class="step-description">
        <h3>{{ $t('discord.join') }}</h3>
        <i18n-t tag="p" keypath="discord.intro" scope="global">
          <template v-slot:br>
            <br>
          </template>
        </i18n-t>
        <div class="w-full flex">
          <a
            :href="CTF_DISCORD_SERVER_ADDRESS!"
            target="_blank"
            class="join-discord-button mx-auto"
            role="link"
          >
            <div class="w-[1em] h-[1em] inline-block align-bottom mr-1  ">
              <ChatBubbleLeftRightIcon />
            </div>
            {{ $t('discord.joinButton') }}
          </a>
        </div>
      </div>
    </div>

    <div class="join-step">
      <div class="step-icon">
        <LinkIcon />
      </div>
      <div class="step-description">
        <h3>{{ $t('discord.link') }}</h3>
        <i18n-t tag="p" keypath="discord.identificationCode" scope="global">
          <template v-slot:br>
            <br>
          </template>
        </i18n-t>
        <div class="flex flex-row items-center justify-center my-5 gap-2">
          <p class="font-mono text-xl bg-accentHover text-white rounded-md px-3 text-center py-1 grow">{{ user.externalId }}</p>
          <button
            class="hover:bg-accentHover px-3 py-2 rounded overflow-hidden transition-colors duration-200"
            role="button"
            :title="$t('discord.copy')"
            @click.stop="copyToClipboard"
          >
            <InplaceReplaceTransition duration="short">
              <ClipboardIcon v-if="!copied" class="w-6 h-6" />
              <CheckCircleIcon v-else class="w-6 h-6 fill-primaryHighlight" />
            </InplaceReplaceTransition>
          </button>
        </div>
        <p>
          {{ $t('discord.linkDescription') }}
        </p>
      </div>
    </div>
  </Modal>
</template>

<script lang="ts" setup>
  import {
    ClipboardIcon,
    LinkIcon,
    UserPlusIcon,
    CheckCircleIcon,
    ChatBubbleLeftRightIcon,
  } from '@heroicons/vue/24/solid'
  import { ref } from 'vue'

  import InplaceReplaceTransition from './InplaceReplaceTransition.vue'

  import Modal from '@/components/ui/Modal.vue'
  import { CTF_DISCORD_SERVER_ADDRESS } from '@/constants'
  import { useUser } from '@/hooks'

  const props = withDefaults(defineProps<{
    modelValue: boolean,
  }>(), {
    modelValue: false,
  })

  const { user } = useUser()

  const emits = defineEmits<{(e: 'update:modelValue', value: boolean): void}>()

  const copied = ref(false)
  const copyTimeout = 2000 // ms

  const copyToClipboard = async () => {
    if (!user.value.isInitialized) {
      return
    }
    await navigator.clipboard.writeText(user.value.externalId)
    copied.value = true
    setTimeout(() => { copied.value = false }, copyTimeout)
  }

</script>

<style scoped lang="scss">
  .join-step {
    @apply flex my-5;

    .step-icon {
      @apply py-1 mr-3 h-7 w-7 flex-none;
    }

    .step-description {
      @apply flex-1;

      h3 {
        @apply font-bold text-xl mt-1 mb-2;
      }
    }
  }

  .join-discord-button {
    @apply inline-block font-bold py-4 px-6 rounded mt-4
        text-standardtext border-secondaryBg bg-secondaryBg hover:text-dark-500 hover:bg-secondaryBgHover leading-none
        whitespace-nowrap;
    width: fit-content;
  }
</style>
