<template>
  <nav
    class="fixed overflow-y-hidden w-full z-40 top-[var(--header-height)]
    transition-all duration-300
    md:fixed md:top-auto md:block md:h-[calc(100%-var(--header-height))] min-w-[var(--sidebar-width)] md:max-w-[var(--sidebar-width)]"
    :class="{
      'h-0': !mobileOpen,
      'h-[calc(100%-var(--header-height))]': mobileOpen,
    }"
    aria-label="Menu"
  >
    <div class="px-3 py-4 overflow-y-auto w-full h-full bg-gray-800 flex flex-col">
      <slot name="beforeMenu" />
      <template v-for="category in menuCategories" :key="category.name">
        <span
          class="inline-block max-w-full whitespace-nowrap text-ellipsis overflow-hidden text-standardtext/80
          text-sm font-semibold tracking-wider shrink-0 cursor-default"
          :title="category.description"
        >
          {{ category.name }}
        </span>
        <ul class="w-full pl-0 space-y-0 list-none list-outside shrink-0">
          <li v-for="link in category.links" :key="`${category.name}.${link.title}`">
            <RouterLink
              :to="link.to"
              exact-active-class="nav-link-active"
              class="flex items-center p-2 text-base font-normal rounded-lg text-white hover:bg-gray-700"
            >
              <component :is="link.icon" v-if="link.icon" class="w-6 h-6 flex-shrink-0 mr-3" />
              <span class="relative inline-block max-w-full whitespace-nowrap text-ellipsis overflow-hidden" :class="{ 'mr-1': link.meta?.isCompleted }">
                {{ link.title }}
              </span>
              <div class="inline-flex ml-auto">
                <span
                  v-if="link.meta?.isCompleted"
                  class="w-4 min-w-4 h-full min-h-4 font-bold text-primary"
                  :class="{
                    'mr-1': link.meta?.running
                  }"
                  :title="$t('navigation.items.chips.completed')"
                >
                  <CheckBadgeIcon />
                </span>
                <span
                  v-if="link.meta?.running"
                  class="w-4 min-w-4 h-full min-h-4 font-bold text-primary"
                  :title="$t('navigation.items.chips.running')"
                >
                  <PlayIcon />
                </span>
              </div>
            </RouterLink>
          </li>
        </ul>
      </template>
      <slot name="afterMenu" />
      <div class="grow" />
      <p class="text-muted text-sm mx-auto">Powered by Flugsicherung 🛫</p>
    </div>
  </nav>
</template>

<script setup lang="ts">
  import { StarIcon, HomeIcon, PaperAirplaneIcon, CalendarDaysIcon } from '@heroicons/vue/24/outline'
  import { PlayIcon, CheckBadgeIcon } from '@heroicons/vue/24/solid'
  import { ComponentOptions, computed, ref, watch } from 'vue'
  import { useI18n } from 'vue-i18n'
  import { RouteLocationRaw } from 'vue-router'

  import { useMetadata } from '@/hooks'
  import { useApi, useUser } from '@/hooks/auth'

  defineProps({
    mobileOpen: {
      type: Boolean,
      required: true,
    },
  })

  type LinkMeta = {
    running: boolean | null
    isCompleted: boolean | null
  }

  type MenuLink = {
    title: string;
    icon: ComponentOptions | null;
    to: RouteLocationRaw;
    order: number;
    meta?: LinkMeta
  }

  type MenuCategory = {
    slug: string;
    name: string;
    description: string;
    order: number;
    links: MenuLink[];
  }

  const { metadata } = useMetadata()
  const { user } = useUser()
  const i18n = useI18n()

  const generalCategory = computed(() => {
    return {
      slug: 'general',
      name: i18n.t('navigation.categories.general'),
      description: i18n.t('navigation.categories.generalDescription'),
      order: 0, // This menu category is never sorted and will always be first.
      links: [
        {
          title: i18n.t('navigation.items.home'),
          icon: {
            render: HomeIcon,
          },
          to: { name: 'home' },
          order: 0,
        },
        ...(metadata.value.ctfHasStarted && user.value.hasSelectedGroup
          ? [{
            title: i18n.t('navigation.items.scoreboard'),
            icon: {
              render: StarIcon,
            },
            to: { name: 'scoreboard' },
            order: 1,
          }]
          : []
        ),
        ...(metadata.value.isFlugzeugEnabled && metadata.value.isDuringPlayTime && user.value.hasSelectedGroup
          ? [{
            title: i18n.t('navigation.items.flugzeugVM'),
            icon: {
              render: PaperAirplaneIcon,
            },
            to: { name: 'flugzeug' },
            order: 3,
          }]
          : []
        ),
      ],
    } as MenuCategory
  })

  const api = useApi()

  const challengeCategories = ref<MenuCategory[]>([])

  watch([metadata, user], async ([newMetadata]) => {
    if (!newMetadata.isInitialized) {
      return
    }

    if (newMetadata.isDuringPlayTime && user.value.hasSelectedGroup) {
      const [fetchCategories, fetchChallenges] = await Promise.all([
        api.value.categories.v1CategoriesGet(),
        api.value.challenges.v1ChallengesGet(),
      ])
      const additionalCategories: Record<string, MenuCategory> = {}
      for (const category of fetchCategories) {
        additionalCategories[category.slug] ||= {
          slug: category.slug,
          name: category.name,
          description: category.description,
          order: category.order,
          links: [],
        }
      }

      for (const challenge of fetchChallenges) {
        const challengeMenu = {
          title: challenge.name,
          to: {
            name: 'challenge',
            params: { category: challenge.categorySlug, slug: challenge.slug },
          },
          icon: null,
          order: challenge.order,
        } as MenuLink

        const challengeRunning = user.value.runningChallengeInstances.some((val) =>
          val.categorySlug === challenge.categorySlug && val.challengeSlug === challenge.slug,
        )
        const challengeCompleted = user.value.completedChallenges.some((val) =>
          val.categorySlug === challenge.categorySlug && val.challengeSlug === challenge.slug,
        )

        challengeMenu.meta = { running: challengeRunning, isCompleted: challengeCompleted }
        additionalCategories[challenge.categorySlug].links.push(challengeMenu)
      }

      challengeCategories.value = Object.values(additionalCategories).sort((a, b) => {
        const order = (a.order ?? 0) - (b.order ?? 0)
        if (order !== 0) {
          return order
        }
        return a.name.localeCompare(b.name)
      })
    }
  }, { immediate: true })

  const menuCategories = computed(() => {
    return [generalCategory.value, ...challengeCategories.value]
      .map((category) => {
        const clonedLinks = [...category.links]
        // Sort by links within the category.
        clonedLinks.sort((a, b) => {
          const order = (a.order ?? 0) - (b.order ?? 0)
          if (order !== 0) {
            return order
          }
          return a.title.localeCompare(b.title)
        })
        return {
          ...category,
          links: clonedLinks,
        }
      })
  })
</script>

<style lang="scss" scoped>
.nav-link-active {
  @apply text-primary;
}
</style>
