<template>
  <PageContentContainer>
    <h1 class="text-5xl mb-2">{{ $t('home.welcome') }}, {{ user.displayName }}</h1>
    <MarkdownContent :markdown="$t('home.intro')" />
    <template v-if="!metadata.ctfHasStarted ">
      <p class="mt-4 text-lg">
        <strong>{{ $t('home.notYetStarted') }}</strong>
      </p>
      <CountDown class="my-8" :date="metadata.ctfStart" />
      <img :src="imgUrl" class="mx-auto my-4" alt="Meme about Pablo Escobar being sad that PCG did not start, yet.">
    </template>
    <ScoreGroupSelector v-else-if="!user.hasSelectedGroup" @update-user-info="refetchUser" />
    <template v-else-if="metadata.ctfHasEnded">
      <div class="mt-4">
        <p class="text-3xl font-bold">
          <strong>{{ $t('home.ctfEnded') }}</strong>
        </p>
      </div>
    </template>
  </PageContentContainer>
</template>

<script setup lang="ts">

  import MarkdownContent from '@/components/MarkdownContent'
  import PageContentContainer from '@/components/PageContentContainer.vue'
  import ScoreGroupSelector from '@/components/ScoreGroupSelector.vue'
  import CountDown from '@/components/landing-page/CountDown.vue'
  import { useUser, useMetadata } from '@/hooks'
  import imgUrl from '@/images/waiting-for-start.jpg'

  const { metadata } = useMetadata()
  const { user, refetch: refetchUser } = useUser()
</script>

<style scoped lang="scss">
  .step {
    @apply flex my-5;

    .step-icon {
      @apply py-1 mr-3 h-7 w-7 flex-none;
    }

    .step-description {
      @apply flex-1;

      h3 {
        @apply font-bold text-xl mt-1 mb-2;
      }

      .os-selector {
        @apply flex items-center justify-center py-3;

        button {
          @apply inline-block px-4 py-1 border-2;

          // Inactive styles
          @apply bg-accentBg hover:bg-accentHover border-gray-600;

          &.active {
            @apply bg-primaryBg hover:bg-primaryBgHover border-primary;
          }
        }
      }
    }
  }
</style>
